import React, { useState } from "react"

import Section from "../../components/section/Section"
import { Imgs } from "../../pagesStructures/asrTts/asrTtsSections/AsrTtsSectionFirst"

interface ImageItem {
  imgAlt: string
  imgSrc: string
  switchBtnText: string
  mainBtnLink: string
  mainBtnText: string
}
interface SliderBannerData {
  heading: string
  subheading?: string
  imageList: Array<ImageItem>
}

export const SliderBanner = ({ className, data, images }: { className: string; data: SliderBannerData; images: Imgs }) => {
  const { heading, subheading, imageList } = data

  const [currentIdx, changeCurrentIdx] = useState(0)

  return (
    <Section className={`${className} sova-section_slider-banner sova-fullscreen-height`}>
      <div className="sova-section-container">
        <h1 className={`sova-section-child sova-h2 sova-section-child_has-subheading-${!!subheading}`}>{heading}</h1>
        {subheading && <h4 className="sova-section-child sova-h4 sova-h4_slider-banner">{subheading}</h4>}
        <div className="sova-section-child">
          <img src={images[imageList[currentIdx].imgSrc]} alt={imageList[currentIdx].imgAlt} className="sova-section-child sova-section-img sova-section-img_slider-banner" />
          <div className="sova-section-child sova-section-child_row sova-section-child_slider-banner">
            <div className="sova-section-child sova-section-child_row slider-banner_btn-group info-action_without-margin-bottom">
              {imageList.map((el: any, i: number) => (
                <button
                  className={`sova-section-child sova-btn slider-banner_btn slider-banner_btn_active-${currentIdx == i} info-action_without-margin-bottom`}
                  key={i}
                  onClick={() => changeCurrentIdx(i)}
                >
                  {imageList[i].switchBtnText}
                </button>
              ))}
            </div>
            <a href={imageList[currentIdx].mainBtnLink} target="blank">
              <button className="sova-section-child sova-btn sova-btn_primary sova-btn_big info-action_without-margin-bottom">{imageList[currentIdx].mainBtnText}</button>
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SliderBanner
