import React from "react"
import SEO from "../components/SEO/seo"
import Layout from "../layout/layout"
import { graphql } from "gatsby"
import IDESections from "../pagesStructures/ide/ideSections"

const IDEPage = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiIdePage.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="chatKit">
      <SEO title="IDEPage" />
      <IDESections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default IDEPage

export const query = graphql`
  query IdePage($url: String) {
    strapiIdePage(url: { eq: $url }) {
      content {
        content {
          dataset {
            Section3 {
              heading
              imgAlt
              imgSrc
              paragraphs
            }
            Section1 {
              heading
              subheading
              imageList {
                imgAlt
                imgSrc
                switchBtnText
                mainBtnLink
                mainBtnText
              }
            }
            bannerBlock {
              btnLink
              btnText
              images {
                imgAlt
                imgSrc
              }
              text
              title
            }
            callToActionBlockFirst {
              buttons {
                btnLink
                btnText
                foreignLink
              }
              heading
              paragraph
              title
              imgSrc
              imgAlt
            }
            infoBlock {
              cards {
                buttonHref
                buttonText
                imgSrc
                text
                title
              }
              heading
            }
          }
        }
        language
      }
    }
  }
`
