import React from "react"

import SliderBanner from "../../blocks/sliderBanner/SliderBanner"
import InfoBlock from "../../blocks/info-cards"
import CallToActionBlockFirst from "../../blocks/call-to-action-block-first"
import { Imgs } from "../../@types/common"
import InfoImg from "../../blocks/infoImg/InfoImg"
import infoImg1 from "../../assets/images/ide/info1.svg"
import infoImg2 from "../../assets/images/ide/info2.svg"
import actionImg from "../../assets/images/ide/action.svg"
import card1Img from "../../assets/images/ide/card1.svg"
import card2Img from "../../assets/images/ide/card2.svg"
import card3Img from "../../assets/images/ide/card3.svg"
import card4Img from "../../assets/images/ide/card4.svg"
import bannerImg1 from "../../assets/images/ide/banner1.svg"
import bannerImg2 from "../../assets/images/ide/banner2.svg"

const bannerImages: Imgs = {
  bannerImg1,
  bannerImg2,
}

const cardImages: Imgs = {
  card1Img: card1Img,
  card2Img: card2Img,
  card3Img: card3Img,
  card4Img: card4Img,
}

const infoImages: Imgs = {
  infoImg1: infoImg1,
  infoImg2: infoImg2,
}

const actionImages: Imgs = {
  actionImg: actionImg,
}

const sections = [
  { component: (key: number, className: string, data: any) => <SliderBanner data={data.data.content.dataset.Section1} className={className} key={key} images={bannerImages} /> },
  { component: (key: number, className: string, data: any) => <InfoImg data={data.data.content.dataset.Section3} className={className} key={key} images={infoImages} /> },
  { component: (key: number, className: string, data: any) => <InfoBlock data={data} className={className} key={key} images={cardImages} /> },
  { component: (key: number, className: string, data: any) => <CallToActionBlockFirst data={data} className={className} key={key} images={actionImages} /> },
]

const IDESections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-ide_${i}`, data))}</>
}

IDESections.displayName = "IDESections"

export default IDESections
